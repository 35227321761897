<template>
  <fieldset :class="{'content-center': contentCenter}">
    <legend :class="{'align-left': labelLeft}">{{title}} <slot name="action"></slot></legend>
    <slot name="button"></slot>
    <slot></slot>
  </fieldset>
</template>

<script>
  export default {
    name: 'Fieldset.vue',
    props:{
      title: {
        type: String,
      },
      labelLeft: {
        type: Boolean,
        default: false
      },
      contentCenter: {
        type: Boolean,
        default: false
      },
    }
  }
</script>

<style lang="scss" scoped>
  fieldset{
    border: solid 1px #bec8cd;
    border-radius: 4px;
    padding: 10px 20px 15px;
    position: relative;
    width: 100%;
    min-height: 100px;
    background-color: #fff;
    margin-bottom: 20px;
    &.content-center{
      text-align: center;
    }
    legend{
      border-radius: 4px;
      font-size: 12px;
      font-weight: bold;
      padding: 4px 12px;
      color: #fff;
      text-transform: uppercase;
      background-color: #bec8cd;
      &.align-left{
        text-align: left;
      }
    }
  }
</style>