import moment         from 'moment';

var pattern = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$','i'); 

export default {
  rules: {
    required:     val => !!val || 'Required.',
    isUrl:        val => !!pattern.test(val) || 'URL must be valid',
    isEmail:      val => /.+@.+\..+/.test(val) || 'E-mail must be valid',
    min:          val => val.length >= 6 || 'Min 6 characters',
    max2mb:       val => !val || val.size < 1000000 || 'Max 1MB',
    onlyImages:   val => !val || val.type == 'image/jpeg' || val.type == 'image/png' || val.type == 'image/bmp' || 'Only JPG, PNG or BMPs are allowed',
    max500chars:  val => !val || val.length <= 500 || 'Max 500 characters',
  },
  randomstring(max,onlynumbers) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    if(onlynumbers){
      possible = "0123456789";
    }

    for (var i = 0; i < max; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  },
  randomnumbers(min,max) {
    return Math.random() * (max - min) + min;
  },
  oneorzero() {
    return (Math.random()>=0.5)? 1 : 0;
  },
  addcommas: function (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  ordinalSuffixOf(num) {
    const j = Number(num) % 10
    const k = Number(num) % 100;

    if (j == 1 && k != 11) {
      return 'st';
    }
    if (j == 2 && k != 12) {
      return 'nd';
    }
    if (j == 3 && k != 13) {
      return 'rd';
    }
    return 'th';
  },
  trimText(str){
    return str.replace(/\r?\n|\r/g,"").replace(/\s/g," ");
  },
  convertToMultiArray(str){
    let strToArray = str.split(/\n/);
    let ret = strToArray.filter(el => {
      return el != null && el != '';
    });
    for(let i = 0; i < ret.length; i++){
      ret[i] = {
        'url': ret[i],
        'status': '0'
      }
    }
    return ret;
  },
  convertToMultiArrayRedirects(str){
    let ret = str.split(/\n\n/);
    for (let i = 0; i < ret.length; i++) {
        ret[i] = ret[i].split(/\n/);
        
        for (let x = 0; x < ret[i].length; x++) {
            ret[i][x] = ret[i][x].split(/:/);
            if(x == 0){
                ret[i][x].unshift('Response');
            }
        }
    }
    return ret;
  },
  getFullDay(date){
    return moment(date).format("dddd, MMMM DD, YYYY");
  },
  hasProtocol(str) {
    let url;
    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  },
  isJsonObject(strData) {
    try {
      JSON.parse(strData);
    } catch (e) {
      return false;
    }
    return true;
  },
  isObject(str) {
    return typeof str === 'object' && str !== null;
  },
  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  },
  appClass(route){
    let path = route.name.toLowerCase();
    let classes = [];
    classes.push(path)
    return classes.join(' ');
  },
  getReportURL(id, prependDomain = false){
    if(prependDomain){
      return window.location.origin + '/report/'+id;
    }else{
      return 'http://localhost:8086/report/'+id;
    }
  },
  printReport(id){
    // let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1024,height=768,left=50,top=50`;
    //open('/report/'+id, 'report', params);
    return 'http://localhost:8086/report/'+id;
    //window.open('http://localhost:8086/report/'+id, '_blank');
  },
  shouldDisable(arr){
    let ret = false;
    for (let i = 0; i < arr.length; i++) {
      if(arr[i]){
        ret = true;
        break;
      }
    }
    return ret;
  },
  shouldShow(arr){
    let ret = true;
    for (let i = 0; i < arr.length; i++) {
      if(!arr[i]){
        ret = false;
        break;
      }
    }
    return ret;
  },
  convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  },
  getCookie(name){
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  },
  setCookie(name, value, days){
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
  },
  deleteCookie(name){
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
}