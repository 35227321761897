<template>
  <v-card elevation="0" outlined>
    <v-img
      :src="require('@/assets/photos/chuttersnap-TSgwbumanuE-unsplash.jpg')"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      height="100px"
    >
      <v-container>
        <v-row align="center">
          <v-col cols="8" class="pa-5 white--text"><b>Current Maintenance (in Cycle)</b></v-col>
          <v-col cols="4">
            <v-select
              background-color="white"
              v-model="month"
              :items="months"
              item-value="id"
              item-text="label"
              dense
              outlined
              hide-details
              small
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
    <v-card-text class="pa-0">
      <v-loader v-if="!currentClientsThisMonth"></v-loader>
      <v-data-table
          :headers="headers" 
          :items="currentClientsThisMonth" 
          item-key="name"
          :items-per-page=item_per_page
          dense
          no-data-text="No sites added yet."
        >
          <template v-slot:item.frequency="{ item }">
            <v-chip small dark :color="getColor(item.frequency)">{{item.frequency}}</v-chip>
          </template>
          <template v-slot:item.with_report="{ item }">
            <v-tooltip top v-if="item.with_report">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon x-small v-bind="attrs" v-on="on" dark @click="$utilities.printReport(item.id)">
                  <v-icon small color="blue darken-1">mdi-file-pdf-box</v-icon>
                </v-btn>
              </template>
              <span>View Report</span>
            </v-tooltip>
          </template>
          <template v-slot:item.started_on="{ item }">
            {{$date.fullDate(item.started_on)}}
          </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import Moment from 'moment';
  export default {
    name: 'CompanyCalendar.vue',
    data() {
      return {
        item_per_page: 10,
        month: Moment(new Date()).format("MM"),
        headers: [
          { text: "Name", value: "name", width: '60%' },
          { text: "Report", value: "with_report", width: '20%' },
          { text: "Frequency", value: "frequency", width: '20%' },
        ],
      }
    },
    computed: {
      user(){
        return this.$store.getters['users/user'];
      },
      meta(){
        return this.$store.getters['userMeta/get'];
      },
      sites(){
        return this.$store.getters['sites/all'];
      },
      months(){
        return this.$date.months();
      },
      currentClientsThisMonth(){
        return this.sites.filter(this.filterClients);
      }
    },
    created(){
      this.buildClientList();
    },
    methods:{
      checkCycle(){
        if(this.meta){
          let cycleDay = this.meta.cycle;
          let currentDay = Moment(new Date()).format("DD");
          console.log(cycleDay, currentDay);
        }
      },
      getColor(str){
        let arr = this.$strings.frequencyOptions();
        let obj = arr.find(({ label }) => label === str);
        return obj.color;
      },
      checkEvenOdd(date){
        let selectedMonth = this.$date.monthNumberByDate(date);
        return (selectedMonth % 2 == 0);
      },
      checkWithinQuarter(date){
        let self = this;
        let startedMonth = this.$date.monthNumberByDate(date);
        let quarterMatrix = [
          ['01','04','07','10'],
          ['02','05','08','11'],
          ['03','06','09','12'],
        ];
        for (let index = 0; index < quarterMatrix.length; index++) {
          const arr = quarterMatrix[index];
          if(arr.includes(startedMonth) && arr.includes(self.month)){
            return true;
          }
        }
        return false;
      },
      filterClients(client){
        let self = this;
        if(client.active == 0){
          return false;
        }
        switch(client.frequency){
          case 'Monthly':
            return true;
          case 'Bi-Monthly':
            if(this.checkEvenOdd(client.started_on) == this.checkEvenOdd(self.month)){
              self.currentClientsThisMonth.push(client);
              return true;
            }
            return false;
          case 'Quarterly':
            if(this.checkWithinQuarter(client.started_on)){
              self.currentClientsThisMonth.push(client);
              return true;
            }
            return false;
          default:
            return false;
        }
      },
      buildClientList(){
        this.$store.dispatch("sites/allWithReportFlag", {
          month: this.month
        });
      }
    },
    watch:{
      month (){
        this.buildClientList();
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>