<template>
   <v-container d-flex>
      <v-card class="mx-auto" width="300">
         <v-card-title>
            <span class="text-subtitle-1">LIST OF MODULES</span>
         </v-card-title>
         <v-list dense>
            <v-list-item-group color="primary">
               <v-list style="max-height: 250px; overflow-y: auto;">
                  <v-list-item v-for="(item) in available" :key="item.no">
                     <template v-slot:default>
                        <v-list-item-action>
                           <v-checkbox :input-value="item.selected" @change="onSelectionAdd($event, item)"/>
                        </v-list-item-action>
                        <v-list-item-content left>
                           <v-list-item-title v-text="item.name" />
                        </v-list-item-content>
                     </template>
                  </v-list-item>
               </v-list>
            </v-list-item-group>
         </v-list>
      </v-card>
      <v-container d-flex flex-column justify-center align-center class="div-action">
         <v-btn elevation="0" color="blue-grey darken-4" dark small @click="onAssignClick" mb-2>
            Assign
            <v-icon right small>mdi-chevron-right</v-icon>
         </v-btn>
         <v-btn elevation="0" color="blue-grey darken-4" dark small @click="onRemoveClick" mb-2>
            <v-icon left small>mdi-chevron-left</v-icon>
            Remove
         </v-btn>
      </v-container>
      <v-card class="mx-auto" width="250">
         <v-card-title>
            <span class="text-subtitle-1">SELECTED</span>
         </v-card-title>
         <v-list dense>
            <v-list-item-group color="primary" v-model="selectedIndex">
               <v-list style="max-height: 250px; overflow-y: auto;">
                  <v-list-item v-for="(item, index) in assigned" :key="item.no" :value="index">
                     <template v-slot:default>
                        <v-list-item-action>
                           <v-checkbox :input-value="item.selected" @change="onSelectionRemove($event, item)"/>
                        </v-list-item-action>
                        <v-list-item-content left>
                           <v-list-item-title v-text="item.name" />
                        </v-list-item-content>
                     </template>
                  </v-list-item>
               </v-list>
            </v-list-item-group>
         </v-list>
      </v-card>
      <v-container d-flex flex-column justify-center align-center class="div-order">
         <v-btn elevation="0" color="blue-grey darken-4" dark small @click="moveItem('up')">
            <v-icon right small>mdi-chevron-up</v-icon>
         </v-btn>
         <v-btn elevation="0" color="blue-grey darken-4" dark small @click="moveItem('down')">
            <v-icon left small>mdi-chevron-down</v-icon>
         </v-btn>
      </v-container>
   </v-container>
</template>

<script>
   export default {
      name: 'ReportSelector.vue',
      data() {
         return {
            refId: 0,
            available: [],
            assigned: [],
            selectedIndex: -1,
         }
      },
      props:{
         value:{
            type: Array
         },
         id: {
            type: [Number, String]
         }
      },
      mounted() {
         this.initList();
      },
      methods: {
         updateValue (value) {
            this.$emit('input', value);
         },
         initList() {
            const rpt = this.$strings.scanList().map(item => {
               item.selected = false;
               return item;
            });
            if (this.value) {
               this.available = rpt.filter(r => !this.value.includes(r.name)).sort((a, b) => a.name.localeCompare(b.name));
               this.assigned = this.value
                  .map(val => rpt.find(r => r.name === val))
                  .filter(item => item); 
               this.selectedIndex = 0;
            } else {
               this.available = rpt.sort((a, b) => a.name.localeCompare(b.name));
            }
         },
         onSelectionAdd(selected, item) {
            const index = this.available.indexOf(item);
            this.available[index].selected = selected;
         },
         onSelectionRemove(selected, item) {
            const index = this.assigned.indexOf(item);
            this.assigned[index].selected = selected;
         },
         onAssignClick() {
            const selected = this.available.filter(r => r.selected === true);
            if (selected.length > 0) {
               const items = selected.map((item) => {
                  item.selected = false;
                  return item;
               })
               this.assigned.push(...items);
               this.available = this.available.filter(r => !this.assigned.some(a => a.no === r.no));
               this.available.sort((a, b) => a.name.localeCompare(b.name));
               this.updateValue(this.assigned.map(r => r.name));
            }
         },
         onRemoveClick() {
            const selected = this.assigned.filter(r => r.selected === true);
            if (selected.length > 0) {
               const items = selected.map((item) => {
                  item.selected = false;
                  return item;
               })
               this.available.push(...items);
               this.assigned = this.assigned.filter(r => !this.available.some(a => a.no === r.no));
               this.available.sort((a, b) => a.name.localeCompare(b.name));
               this.updateValue(this.assigned.map(r => r.name));

               if (this.assigned.length === 0) {
                  this.selectedIndex = -1;
               }
            }
         },
         moveItem(direction) {
            if (this.selectedIndex < 0) return;
            const index = this.selectedIndex;
            if (direction === 'up' && index > 0) {
               this.swapItems(index, index - 1);
            } else if (direction === 'down' && index < this.assigned.length - 1) {
               this.swapItems(index, index + 1);
            }
         },
         swapItems(fromIndex, toIndex) {
            const temp = this.assigned[fromIndex];
            this.assigned.splice(fromIndex, 1);
            this.assigned.splice(toIndex, 0, temp);
            this.selectedIndex = toIndex;
            this.updateValue(this.assigned.map(r => r.name));
         },
      },
      watch: {
         id(to) {
            this.refId = to;
            this.initList();
         }
      }
   }
</script>

<style lang="scss">
.div-action {
   width: 120px;
   gap: 5px;
   .v-btn {
      width: 100%;
   }
}
.div-order {
   width: 10px;
   gap: 5px;
   .v-btn {
      min-width: 25px !important;
      padding: 0 !important;
      .v-btn__content {
         .v-icon--right {
            margin: 0 !important;
         }
         .v-icon--left {
            margin: 0 !important;
         }
      }
   }
}
</style>