<template>
  <v-dialog v-model="value" max-width="650px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">Delete User?</span>
        <v-spacer></v-spacer>
        <v-btn small dark fab right text @click="updateValue(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center py-5" v-if="this.users">
        <b>Are you sure you want to delete <b>{{getUserName}}</b>?</b>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-5">
        <v-spacer></v-spacer>
        <v-btn dark color="blue-grey lighten-1" @click="save">Yes</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UsersDelete.vue',
  data() {
    return {
    }
  },
  emits: ['update:modelValue'],
  props:{
    value:{
      type: Boolean,
      default: false
    },
    id:{
      type: Number
    },
  },
  computed:{
    users(){
      return this.$store.getters['users/all'];
    },
    getUserName(){
      let user = this.users.find(user => user.id == this.id);
      return (user) ? user.name : "";
    }
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async save(){
      let self = this;
      let index = this.users.findIndex(user => user.id == this.id);
      await this.$store.dispatch("users/delete", {
        id: this.id,
        index: index
      })
      .then(() =>{
        self.updateValue(false);
      });
    }
  }
}
</script>

<style>

</style>