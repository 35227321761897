<template>
  <div class="news">
    <v-card elevation="0" outlined>
      <v-card-title>
        Wordfence Security News
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page=item_per_page
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="title"
          show-expand
          class="elevation-0"
          hide-default-header
        >
          <template v-slot:item.title="{ item }">
            <a :href="item.link" class="full-button" target=_blank>
              <div><h4>{{ item.title }}</h4></div>
              <span class="caption">{{$date.fullDateTime(item.pubDate)}}</span>
            </a>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div v-html="item.content" class="data-content"></div>
              </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <br>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'WordpressSecurityNews.vue',
    data() {
      return {
        item_per_page: 4,
        expanded: [],
        singleExpand: false,
        items: [],
        headers: [
          { text: 'Title', value: 'title', width: '90%'},
        ],
      }
    },
    computed: {
    },
    async created(){
      let self = this;
      await axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://www.wordfence.com/blog/category/research/threat-research/feed/')
        .then((response) => {
          self.items = response.data.items;
        });
    },
  }
</script>

<style lang="scss">
  .news{
    .full-button{
      line-height: 1.3em;
      padding: 20px 5px;
      display: block;
      white-space: normal;
      text-decoration: none;
      word-break: break-word;
      h4{
        font-size: 16px;
      }
    }
    .data-content{
      width: 100%;
      line-height: 1.5em;
      padding: 20px;
    }
    img{
      max-width: 100%;
      height: auto;
    }
    pre{
      white-space: pre-line;
    }
    .v-data-table{
      table{
        tr{
          td{
            &:nth-child(1){
              width: 50px;;
            }
          }
        }
      }
    }
  }
</style>