<template>
  <div :class="{'logo': true, 'icon': showIcon, 'onlyPrint': onlyPrint, 'big': showBig, 'inline': isInline}">
    <img :src="imgPath" :style="styles">
  </div>
</template>

<script>
  export default {
    name: 'Logo.vue',
    data() {
        return {}
    },
    props:{
      onlyPrint: {
        type: Boolean,
        default: false
      },
      simple: {
        type: Boolean,
        default: false
      },
      showPNG: {
        type: Boolean,
        default: false
      },
      showBig: {
        type: Boolean,
        default: false
      },
      showIcon:{
        type: Boolean,
        default: false
      },
      blackWhite:{
        type: Boolean,
        default: false
      },
      isInline:{
        type: Boolean,
        default: false
      },
      width:{
        type: String
      }
    },
    computed:{
      styles(){
        if(this.width){
          return {'width': this.width}
        }
        return {};
      },
      imgPath(){
        if(this.simple){
          return require('@/assets/logo-hounddog-simple.svg');
        }
        if(this.showPNG){
          if(this.showIcon){
            if(this.blackWhite){
              return require('@/assets/logo-hounddog.svg');
            }
            return require('@/assets/logo-hounddog.png');
          }else{
            return require('@/assets/logo-hounddog.png');
          }
        }else{
          if(this.showIcon){
            if(this.blackWhite){
              return require('@/assets/logo-hounddog.svg');
            }
            return require('@/assets/logo-hounddog.svg');
          }else{
            return require('@/assets/logo-hounddog.svg');
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  .logo{
    padding: 0px;
    display: flex;
    align-content: center;
    max-width: 160px;
    margin: auto;
    &.big{
      width: 300px;
      max-width: none;
      margin: 20px auto;
    }
    &.icon{
      width: 50px;
      height: 50px;
    }
    &.inline{
      display: inline-block;
    }
    img{
      max-width: 100%;
      margin: auto;
    }
    &.onlyPrint{
      display: none;
    }
  }
  @media print {
    .logo{
      .onlyPrint{
        display: flex !important;
      }
    }
  }
</style>