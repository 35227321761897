<template>
  <v-dialog v-model="value" width="600px" persistent>
    <v-form ref="form" v-model="form.valid" lazy-validation>
      <v-card>
        <v-card-title>
          <span class="text-h5">Reorder Sections</span>
          <v-btn small dark fab absolute right text @click="updateValue(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container class="pa-5">
            <v-row>
              <v-col>
                <v-row>
                  <v-col><b>Section order and/or removal</b></v-col>
                  <v-col align="end"><v-btn @click="resetSections()" x-small color="primary">Reset Sections</v-btn></v-col>
                </v-row>
                <v-divider class="my-3"></v-divider>
                <div class="draggable-items" v-if="Array.isArray(form.fields.settings)">
                  <draggable v-model="form.fields.settings" group="settings" @start="drag=true" @end="drag=false" handle=".handle">
                    <v-row dense class="drag-row" :key="index" avatar v-for="(setting, index) in form.fields.settings">
                      <v-col cols="1">
                        <v-btn x-small icon class="handle">
                          <v-icon>mdi-drag</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col>{{ setting }}</v-col>
                      <v-col cols="1">
                        <v-btn x-small icon @click="deleteSection(setting)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </draggable>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-5">
          <v-spacer></v-spacer>
          <v-btn dark color="blue-grey lighten-1" @click="save">Save</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  name: 'ScanReorder.vue',
  data() {
    return {
      form:{
        fields:{
          settings: []
        }
      }
    }
  },
  components: { 
    Draggable
  },
  emits: ['update:modelValue'],
  props:{
    value: {
      type: Boolean,
      default: false,
    },
    id:{
      type: Number
    },
  },
  created(){
    this.get();
  },
  methods:{
    updateValue (value) {
      this.$emit('input', value);
    },
    async get(){
      this.form.fields.settings = this.$strings.settingsDefault(true, true);
    },
    async save(){
      if(this.$refs.form.validate()){
        let fields = { ...this.form.fields};
        await this.$store.dispatch("scans/update", fields);
        this.updateValue(false);
      }else{
        this.systemMessage('Please validate the form!');
      }
    },
    resetSections(){
      this.form.fields.settings = this.$strings.settingsDefault(true, true);
    },
    deleteSection(section){
      this.form.fields.settings.splice(this.form.fields.settings.indexOf(section), 1);
    },
    checkSettings(){
      if(!Array.isArray(this.form.fields.settings) && this.form.fields.settings != undefined){
        this.form.fields.settings = JSON.parse(this.form.fields.settings);
      }
    },
  },
  watch:{
    id(to){
      this.form.fields.id = to;
      this.get();
    },
    'form.fields.settings'(){
      this.checkSettings();
    }
  }
}
</script>

<style>

</style>