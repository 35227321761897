<template>
  <div class="sidebar-main">
    <v-layout row wrap align-center text-center fill-height class="gray-gradient golden-border pa-5 ma-0 rounded-xl">
      <v-flex class="white--text">
        <slot></slot>>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  export default {
    name: 'Zen.vue',
    data() {
      return {

      }
    },
    computed:{
    }
  }
</script>

<style>
  .sidebar-main{
    height: 520px;
  }
</style>