import Vue from 'vue';

import UserInfo                     from './UserInfo.vue';
import AccountInfo                  from './AccountInfo.vue';
import Zen                          from './Zen.vue';
import Sidebar                      from './Sidebar.vue';

Vue.component('v-user-info',        UserInfo);
Vue.component('v-account-info',     AccountInfo);
Vue.component('v-zen',              Zen);
Vue.component('v-sidebar',          Sidebar);
