<template>
  <v-footer padless height="160px">
    <v-container>
      <v-row align="center" justify="center">
        <v-col class="text-center align-center">
          <v-logo :simple="true" width="100px"></v-logo>
          <span class="text-caption">&copy; Copyright 2022 Hounddog is a product of KeenWP.com. All Rights Reserved. </span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer.vue'
  }
</script>

<style lang="scss" scoped>
  .v-footer{
    background-color: transparent;
    .logo-wrapper{
      .logo{
        padding: 0;
      }
    }
  }
</style>