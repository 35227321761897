import Vue from 'vue';

import CompanyCalendar              from './CompanyCalendar.vue';
import FooterDisclaimer             from './FooterDisclaimer.vue';
import Integrations                 from './Integrations.vue';
import MaintenanceCycle             from './MaintenanceCycle.vue';
import ReportBranding               from './ReportBranding.vue';
import AccountContact               from './AccountContact.vue';
import AccountPassword              from './AccountPassword.vue';
import UsersLogs                    from './UsersLogs.vue';
import WordpressSecurityNews        from './WordpressSecurityNews.vue';
import WordfenceVulnerabilities     from './WordfenceVulnerabilities.vue';
import SiteNotes                    from './SiteNotes.vue';


Vue.component('v-company-calendar',         CompanyCalendar);
Vue.component('v-footer-disclaimer',        FooterDisclaimer);
Vue.component('v-integrations',             Integrations);
Vue.component('v-maintenance-cycle',        MaintenanceCycle);
Vue.component('v-report-branding',          ReportBranding);
Vue.component('v-account-contact',          AccountContact);
Vue.component('v-account-password',         AccountPassword);
Vue.component('v-users-logs',               UsersLogs);
Vue.component('v-wp-security-news',         WordpressSecurityNews);
Vue.component('v-wordfence-vulnerabilities',WordfenceVulnerabilities);
Vue.component('v-drawer-site-notes',        SiteNotes);
