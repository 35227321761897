<template>
  <div class="attachment">
    <v-layout>
      <v-flex class="py-5 px-3" align-self-center>
        <v-overlay :value="loading" absolute color="blue-grey darken-4">
          <v-progress-circular indeterminate size="64" color="primary" />
        </v-overlay>
        <v-layout class="mb-4">
          <v-flex>
            Attachment List 
          </v-flex>
          <v-flex class="text-right font-weight-bold">
            [{{ fileList.length }} total]
          </v-flex>
        </v-layout>
        <v-list outlined class="py-0" style="height: 300px; max-height: 300px; overflow-y: auto;" v-if="fileList.length > 0">
          <v-subheader class="list-header">
            <v-btn dark color="blue-grey lighten-1" small @click="onAdd">
              <v-icon size="12" style="margin-right: 5px;">mdi-plus</v-icon>
              <span>Add</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="loading" :loading="loading" dark color="blue-grey lighten-1" @click="onSave()" small>Save</v-btn>
            <v-btn v-else :disabled="!allowSave" color="blue-grey lighten-1" @click="onSave()" small>Save</v-btn>
          </v-subheader>
          <v-list-item
            v-for="(item, index) in fileList"
            :key="index"
            style="border-bottom: 1px solid #e0e0e0;"
          >
            <v-list-item-icon class="mr-3">
              <v-avatar
                color="blue-grey"
                size="18"
              >
                <span class="white--text text-caption">{{index+1}}</span>
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content style="padding: 0;">
              <v-layout>
                <v-flex>
                  <v-text-field
                    v-model="item.label"
                    outlined 
                    dense  
                    hide-details="auto"
                    class="rounded-0">
                    {{`Attachment - ${index+1}`}}
                  </v-text-field>
                </v-flex>
                <v-flex class="chip-label">
                  <template v-if="item.file !== null">
                    <v-chip v-if="item.uploaded === true" class="rounded-0">{{ item.name }}</v-chip>
                    <v-chip v-else class="rounded-0">{{ item.file }}</v-chip>
                  </template>
                </v-flex>
              </v-layout>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn-toggle mandatory background-color="blue-grey lighten-1" class="ml-2">
                <v-tooltip>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small color="blue-grey lighten-1" icon v-bind="attrs" v-on="on" @click="onFileUpload(index)" :disabled="item.uploaded === false">
                      <v-icon color="white" small>mdi-upload</v-icon>
                    </v-btn>
                  </template>
                  <span>Upload</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small color="blue-grey lighten-1" icon v-bind="attrs" v-on="on" @click="onFileView(item)" :disabled="item.file === null">
                      <v-icon color="white" small>mdi-magnify</v-icon>
                    </v-btn>
                  </template>
                  <span>View</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small color="blue-grey lighten-1" icon v-bind="attrs" v-on="on" @click="onFileRemove(index)">
                      <v-icon color="white" small>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Document</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-list outlined class="py-0" style="height: 300px; max-height: 300px; overflow-y: auto;" v-else>
          <v-subheader class="list-header">
            <v-btn dark color="blue-grey lighten-1" small @click="onAdd">
              <v-icon size="12" style="margin-right: 5px;">mdi-plus</v-icon>
              <span>Add</span>
            </v-btn>
          </v-subheader>
          <v-list-item>
            No documents attached yet!
          </v-list-item>
        </v-list>
        <v-layout>
          <v-flex>
            <v-file-input  ref="fileUploader" v-model="fileUploader" label="Choose the document" outlined accept="image/*,.pdf" style="display: none;" @change="onFileSelected" />
          </v-flex>
        </v-layout>
      </v-flex>
    
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "Attachment.vue",
  data() {
    return {
      form: {
        valid: false,
        fields: {
          id: 0,
          files: [],
        },
      },
      saving: false,
      loading: false,
      fileUploader: [],
      fileIndex: 0
    };
  },
  props: {
    item: {
      type: Object,
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    fileList() {
      return this.form.fields.files.filter((item) => item.removed === false);
    },
    allowSave() {
      return this.form.fields.files.every((item) => item.file !== null);
    }
  },
  methods: {
    init() {
      this.loading = false;
      this.form.fields.id = this.item.id;
      this.form.fields.files = this.item.attachments ? this.item.attachments.map((item) => ({ label: item.file_label, file: item.file_url, removed: false, uploaded: false, name: "" })) : [];
    },
    isLabelUnique(label, currentIndex) {
      return this.form.fields.files.filter((item, idx) => item.label === label && idx !== currentIndex).length === 0;
    },
    onAdd() {
      const Cnt = this.form.fields.files.length;
      this.form.fields.files.push({ file: null, label: `Attachment ${Cnt}`, url: "", removed: false, uploaded: true, name: "" });
    },
    onFileUpload(index) {
      this.fileIndex = index;
      const fileInputElement = this.$refs.fileUploader.$el.querySelector('input[type="file"]');
      if (fileInputElement) {
        fileInputElement.click();
      } else {
        console.error("File input element not found.");
      }
    },
    onFileSelected(file) {
      if (file && this.fileIndex >= 0) {
        this.form.fields.files[this.fileIndex].file = file;
        this.form.fields.files[this.fileIndex].label =  `Attachment - ${this.fileIndex}`;
        this.form.fields.files[this.fileIndex].name = `${file.name}`;
        this.fileUploader = null;
      }
    },
    onFileRemove(index) {
      const item = this.form.fields.files[index];
      if (item.uploaded === false) {
        this.form.fields.files[index].removed = true;
        return;
      }
      this.form.fields.files.splice(index, 1);
      let Cnt = 0;
      if (this.form.fields.files) {
        this.form.fields.files.map((f) => {
          if (f.file === null) {
            Cnt += 1;
            f.label = `Attachment ${Cnt}`
          }
          return f;
        });
      }
    },
    onFileView(item) {
      if (item.file) {
        const fileUrl = URL.createObjectURL(item.file);
        window.open(fileUrl, '_blank');
      }
    },
    onSave() {
      this.loading = true;
      this.$emit("save", this.form.fields, this.item);
    },
  },
  watch: {
    value(to) {
      if (to) {
        this.init();
      }
    },
  },
};
</script>

<style scoped>
.v-list-item__icon {
  align-self: center;
}
.list-header {
  border-bottom: 1px solid #ddd;
  padding: 0 6px !important;
}
.chip-label {
  margin-left: 10px;
  display: flex;
  align-items: center;
  width: 250px;
}
</style>