export default {
  approvedStatus(str) {
    switch (str) {
      case 200:
        return true;
      default:
        return false;
    }
  },
  checkValue(str) {
    switch (str) {
      case false:
      case "false":
      case "no":
      case 0:
      case "0":
        return false;
      case true:
      case "true":
      case "yes":
      case 1:
      case "1":
        return true;
    }
  },
  securityCheckLabel(str) {
    switch (str) {
      case "is_suspended_site":
        return "Is site suspended?";
      case "is_most_abused_tld":
        return "Is domain most abused TLD";
      case "is_robots_noindex":
        return "Is the site not indexed?";
      case "is_website_accessible":
        return "Is website accessible?";
      case "is_empty_page_content":
        return "Is the website content empty?";
      case "is_redirect_to_search_engine":
        return "Is the domain redirected to a search engine?";
      case "is_http_status_error":
        return "Is the http status an error?";
      case "is_http_server_error":
        return "Is there an http server error?";
      case "is_http_client_error":
        return "Is there an http client error?";
      case "is_empty_page_title":
        return "Is the page title empty?";
      case "is_ipv6_enabled":
        return "Is ipv6 enabled?";
      case "is_domain_blacklisted":
        return "Is the domain blacklisted?";
      case "is_suspicious_domain":
        return "Is this a suspicious domain?";
      case "is_sinkholed_domain":
        return "Is the domain sinkholed?";
      case "is_directory_listing":
        return "Is the domain displaying a directory listing?";
      case "is_domain_ipv4_assigned":
        return "Is the domain IPV4 Assigned?";
      case "is_domain_ipv4_private":
        return "Is the domain IPV4 Private?";
      case "is_domain_ipv4_loopback":
        return "Is the domain IPV4 Loopbacked?";
      case "is_domain_ipv4_reserved":
        return "Is the domain IPV4 Reserved?";
      case "is_domain_ipv4_valid":
        return "Is the domain IPV4 Valid?";
      case "is_uncommon_host_length":
        return "Does the the site have an uncommon host length?";
      case "is_uncommon_dash_char_count":
        return "Is there an uncommon dash character count?";
      case "is_uncommon_dot_char_count":
        return "Is there an uncommon dot character count?";
      case "is_email_configured":
        return "Is server email configured?";
      case "is_email_spoofable":
        return "Is server email spoofable?";
      case "is_dmarc_configured":
        return "Is DMARC Configured?";
      case "is_dmarc_enforced":
        return "Is DMARC Enforced?";
      case "is_external_redirect":
        return "Is there an external redirect?";
      case "is_custom_404_configured":
        return "Is custom 404 configured?";
      case "is_valid_https":
        return "Is this a valid https?";
      case "is_ssl_blacklisted":
        return "Is the SSL blacklisted?";
      case "is_defaced_heuristic":
        return "Is the site defaced heuristic?";
      case "is_website_popular":
        return "Is the website popular?";
      case "is_domain_recent":
        return "Is the domain recent?";
      case "is_domain_very_recent":
        return "Is the domain very recent?";
      case "is_heuristic_pattern":
        return "Is there a heuristic pattern?";
      case "is_free_email":
        return "Is there free email?";
      case "is_risky_geo_location":
        return "Is the site hosted at a risky geo location?";
      case "is_china_country":
        return "Is the site hosted in China?";
      case "is_nigeria_country":
        return "Is the site hosted in Nigeria?";
      case "is_high_discount":
        return "Is the site highly discounted?";
      default:
        return str;
    }
  },
  securityCheckColor(str) {
    switch (str) {
      case "Pass":
        return "green";
      case "Alert":
        return "red";
      case "Yes":
        return "grey";
      case "No":
        return "grey";
    }
  },
  securityCheckBoolean(str, value) {
    switch (str) {
      case "is_suspended_site":
        return value ? "Alert" : "Pass";
      case "is_most_abused_tld":
        return value ? "Alert" : "Pass";
      case "is_robots_noindex":
        return value ? "Alert" : "Pass";
      case "is_website_accessible":
        return value ? "Pass" : "Alert";
      case "is_empty_page_content":
        return value ? "Alert" : "Pass";
      case "is_redirect_to_search_engine":
        return value ? "Alert" : "Pass";
      case "is_http_status_error":
        return value ? "Alert" : "Pass";
      case "is_http_server_error":
        return value ? "Alert" : "Pass";
      case "is_http_client_error":
        return value ? "Alert" : "Pass";
      case "is_empty_page_title":
        return value ? "Alert" : "Pass";
      case "is_ipv6_enabled":
        return value ? "Yes" : "No";
      case "is_domain_blacklisted":
        return value ? "Alert" : "Pass"; //
      case "is_suspicious_domain":
        return value ? "Alert" : "Pass";
      case "is_sinkholed_domain":
        return value ? "Alert" : "Pass";
      case "is_directory_listing":
        return value ? "Alert" : "Pass";
      case "is_domain_ipv4_assigned":
        return value ? "Yes" : "No";
      case "is_domain_ipv4_private":
        return value ? "Yes" : "No";
      case "is_domain_ipv4_loopback":
        return value ? "Yes" : "No";
      case "is_domain_ipv4_reserved":
        return value ? "Yes" : "No";
      case "is_domain_ipv4_valid":
        return value ? "Yes" : "No";
      case "is_uncommon_host_length":
        return value ? "Alert" : "Pass";
      case "is_uncommon_dash_char_count":
        return value ? "Alert" : "Pass";
      case "is_uncommon_dot_char_count":
        return value ? "Alert" : "Pass";
      case "is_email_configured":
        return value ? "Yes" : "No";
      case "is_email_spoofable":
        return value ? "Alert" : "Pass";
      case "is_dmarc_configured":
        return value ? "Yes" : "No";
      case "is_dmarc_enforced":
        return value ? "Yes" : "No";
      case "is_external_redirect":
        return value ? "Alert" : "Pass";
      case "is_custom_404_configured":
        return value ? "Pass" : "Alert";
      case "is_valid_https":
        return value ? "Pass" : "Alert";
      case "is_ssl_blacklisted":
        return value ? "Alert" : "Pass";
      case "is_defaced_heuristic":
        return value ? "Alert" : "Pass";
      case "is_website_popular":
        return value ? "Yes" : "No";
      case "is_domain_recent":
        return value ? "Yes" : "No";
      case "is_domain_very_recent":
        return value ? "Yes" : "No";
      case "is_heuristic_pattern":
        return value ? "Alert" : "Pass";
      case "is_free_email":
        return value ? "Yes" : "No";
      case "is_risky_geo_location":
        return value ? "Alert" : "Pass";
      case "is_china_country":
        return value ? "Alert" : "Pass";
      case "is_nigeria_country":
        return value ? "Alert" : "Pass";
      default:
        return "NA";
    }
  },
  whoisArray() {
    return {
      "Domain Created On": "domain_creation_date",
      Registrar: "registrar",
      IP: "ip",
      "IP Country": "ip_country",
      ISP: "ip_isp",
      Nameservers: "nameservers",
    };
  },
  frequencyOptions() {
    return [
      {
        label: "Monthly",
        value: "Monthly",
        color: "green",
      },
      {
        label: "Bi-Monthly",
        value: "Bi-Monthly",
        color: "blue",
      },
      {
        label: "Quarterly",
        value: "Quarterly",
        color: "orange",
      },
    ];
  },
  frameworkOptions() {
    return [
      {
        label: "Wordpress",
        value: "wordpress",
      },
      {
        label: "SquareSpace",
        value: "squarespace",
      },
      {
        label: "Wix",
        value: "wix",
      },
      {
        label: "Drupal",
        value: "drupal",
      },
      {
        label: "Joomla",
        value: "joomla",
      },
      {
        label: "WebFlow",
        value: "webflow",
      },
      {
        label: "Laravel",
        value: "laravel",
      },
      {
        label: "Shopify",
        value: "shopify",
      },
      {
        label: "Magento",
        value: "magento",
      },
      {
        label: "Weebly",
        value: "weebly",
      },
      {
        label: "Custom",
        value: "custom",
      },
    ];
  },
  packageTypeOptions() {
    return [
      {
        label: "Starter",
        value: "starter",
        image: "https://www.keenwp.com/wp-content/uploads/2022/05/keenwp-service-starter.png",
      },
      {
        label: "Advanced",
        value: "advanced",
        image: "https://www.keenwp.com/wp-content/uploads/2022/05/keenwp-service-advanced.png",
      },
      {
        label: "Premium",
        value: "premium",
        image: "https://www.keenwp.com/wp-content/uploads/2022/05/keenwp-service-premium.png",
      },
    ];
  },
  themeOptions() {
    return [
      {
        label: "Default",
        value: "default",
        color: "red",
      },
      {
        label: "Modern",
        value: "modern",
        color: "gray",
      },
      {
        label: "Classic",
        value: "classic",
        image: "blue",
      },
    ];
  },
  numericSuffix(num) {
    let suffix = "th";
    if (num == 0) suffix = "";
    if (num % 10 == 1 && num % 100 != 11) suffix = "st";
    if (num % 10 == 2 && num % 100 != 12) suffix = "nd";
    if (num % 10 == 3 && num % 100 != 13) suffix = "rd";
    return num + suffix;
  },
  cycleItems() {
    let self = this;
    let ret = [];
    for (let k = 0; k < 28; k++) {
      ret.push({
        value: k + 1,
        text: self.numericSuffix(k + 1) + " of the month",
      });
    }
    return ret;
  },
  settingsDefault(flat = false, onlyDefaults = false) {
    let allSettings = [
      // DO NOT CHANGE THE VALUES
      {
        label: "Recommendations",
        description: "",
        show: true,
        bydefault: false,
      },
      { label: "Snapshot", description: "", show: true, bydefault: true },
      {
        label: "Wordpress Plugins",
        description: "",
        show: true,
        bydefault: false,
      },
      { label: "Risk Score", description: "", show: true, bydefault: true },
      {
        label: "HTTP Response Headers",
        description: "",
        show: true,
        bydefault: true,
      },
      { label: "Server Details", description: "", show: true, bydefault: true },
      { label: "Redirect Chain", description: "", show: true, bydefault: true },
      {
        label: "Technology Stack",
        description: "",
        show: true,
        bydefault: true,
      },
      {
        label: "Security Checks",
        description: "",
        show: true,
        bydefault: true,
      },
      { label: "Domain Related", description: "", show: true, bydefault: true },
      {
        label: "Domain Reputation",
        description: "",
        show: true,
        bydefault: true,
      },
      { label: "SEO", description: "", show: true, bydefault: true },
      { label: "Reverse IP", description: "", show: true, bydefault: true },
      {
        label: "Google Safe Browsing",
        description: "",
        show: true,
        bydefault: true,
      },
      {
        label: "McAfee Site Advisor",
        description: "",
        show: true,
        bydefault: true,
      },
      {
        label: "Norton Safe Web",
        description: "",
        show: true,
        bydefault: true,
      },
      { label: "Sucuri", description: "", show: true, bydefault: true },
      {
        label: "VirusTotal Scan",
        description: "",
        show: true,
        bydefault: true,
      },
      { label: "Lighthouse", description: "", show: true, bydefault: true },
    ];

    allSettings = allSettings.filter((item) => {
      let show = item.show;
      delete item.show;
      return show;
    });

    if (onlyDefaults) {
      allSettings = allSettings.filter((item) => {
        let bydefault = item.bydefault;
        delete item.bydefault;
        return bydefault;
      });
    }

    if (flat) {
      allSettings = allSettings.map((setting) => setting.label).flat();
    }
    return allSettings;
  },
  securityHeaders() {
    return [
      {
        name: "Response Code",
        value: "code",
        required: 1,
        suggestedValue: "200",
        notes: "This is the HTTP response code. It should be 200 for a successful response.",
      },
      {
        name: "Request Date",
        value: "date",
        required: 1,
        suggestedValue: "",
        notes: "This is the date and time of the request.",
      },
      {
        name: "Link Tags",
        value: "link",
        required: 1,
        suggestedValue: "",
        notes:
          "This is the Link header. It is used to indicate that the target resource has a relationship to another resource. You can remove or change this value.",
      },
      {
        name: "Server",
        value: "server",
        required: 1,
        suggestedValue: "",
        notes: "This Server header seems to advertise the software being run on the server but you can remove or change this value.",
      },
      {
        name: "HTTP Status",
        value: "status",
        required: 1,
        suggestedValue: "",
        notes: "This is the HTTP status code. It should be 200 for a successful response.",
      },
      {
        name: "Connection",
        value: "connection",
        required: 0,
        suggestedValue: "",
        notes: "This Connection header seems to advertise the software being run on the server but you can remove or change this value.",
      },
      {
        name: "Transfer Encoding",
        value: "transfer-encoding",
        required: 0,
        suggestedValue: "",
        notes: "This Transfer-Encoding header seems to advertise the software being run on the server but you can remove or change this value.",
      },
      {
        name: "X-Frame-Options",
        value: "x-frame-options",
        required: 1,
        suggestedValue: "DENY",
        notes:
          "X-Frame-Options is a header that tells the browser whether you want to allow your site to be framed or not. By preventing a browser from framing your site you can defend against attacks like clickjacking.",
      },
      {
        name: "X-XSS-Protection",
        value: "x-xss-protection",
        required: 0,
        suggestedValue: "0",
        notes:
          'X-XSS-Protection is a header that stops pages from loading when they detect reflected cross-site scripting (XSS) attacks. The only valid value for this header is "X-XSS-Protection: 0".',
      },
      {
        name: "X-Content-Type-Options",
        value: "x-content-type-options",
        required: 1,
        suggestedValue: "nosniff",
        notes:
          'X-Content-Type-Options is a marker header that tells the browser not to try to MIME-sniff the content type and forces it to stick with the declared content-type. The only valid value for this header is "X-Content-Type-Options: nosniff".',
      },
      {
        name: "Referrer-Policy",
        value: "referrer-policy",
        required: 1,
        suggestedValue: "strict-origin-when-cross-origin",
        notes:
          "Referrer-Policy is a new header that allows a site to control how much information the browser includes with navigations away from a document and should be set by all sites.",
      },
      {
        name: "Content-Type",
        value: "content-type",
        required: 1,
        suggestedValue: "text/html; charset=utf-8",
        notes:
          'Content-Type is a header that tells the browser what the content type of the returned content actually is. The only valid value for this header is "Content-Type: text/html; charset=utf-8".',
      },
      {
        name: "Set-Cookie",
        value: "set-cookie",
        required: 0,
        suggestedValue: "HttpOnly; Secure; SameSite=Strict",
        notes: "Set-Cookie is a header that sets a cookie. You can remove or change this value.",
      },
      {
        name: "Strict-Transport-Securitsy (HSTS)",
        value: "strict-transport-security",
        required: 1,
        suggestedValue: "max-age=63072000; includeSubDomains; preload",
        notes:
          "Strict-Transport-Security is an excellent feature to support on your site and strengthens your implementation of TLS by getting the User Agent to enforce the use of HTTPS. This header is ignored by the browser when your site is accessed using HTTP; this is why it is important to redirect all HTTP traffic to HTTPS.",
      },
      {
        name: "Expect-CT (Certificate Transparency)",
        value: "expect-ct",
        required: 0,
        suggestedValue: "max-age=86400, enforce",
        notes:
          "Expect-CT is a new header that allows a site to opt-in to reporting and/or enforcement of Certificate Transparency requirements. This header is ignored by the browser when your site is accessed using HTTP; this is why it is important to redirect all HTTP traffic to HTTPS.",
      },
      {
        name: "Content-Security-Policy (CSP)",
        value: "content-security-policy",
        required: 1,
        suggestedValue: "NOT-NULL",
        notes:
          "Content-Security-Policy is a powerful addition to the web platform that allows a site to control resources the user agent is allowed to load for a given page. It is recommended to enable CSP with a restrictive policy.",
      },
      {
        name: "Access-Control-Allow-Origin",
        value: "access-control-allow-origin",
        required: 2,
        suggestedValue: "*",
        notes:
          "Access-Control-Allow-Origin is a header that indicates whether the response can be shared with requesting code from the given origin.",
      },
      {
        name: "Cross-Origin-Opener-Policy (COOP)",
        value: "cross-origin-opener-policy",
        required: 2,
        suggestedValue: "same-origin",
        notes: "Cross-Origin Opener Policy allows a document to request a new browsing context group to be created for it.",
      },
      {
        name: "Cross-Origin-Embedder-Policy (COEP)",
        value: "cross-origin-embedder-policy",
        required: 2,
        suggestedValue: "require-corp",
        notes: "Cross-Origin Embedder Policy allows a document to request a new browsing context group to be created for it.",
      },
      {
        name: "Cross-Origin-Resource-Policy (CORP)",
        value: "cross-origin-resource-policy",
        required: 2,
        suggestedValue: "same-site",
        notes: "Cross-Origin Resource Policy allows a document to request a new browsing context group to be created for it.",
      },
      {
        name: "Permissions-Policy (formerly Feature-Policy)",
        value: "permissions-policy",
        required: 2,
        suggestedValue:
          "accelerometer=(), ambient-light-sensor=(), autoplay=(), battery=(), camera=(), display-capture=(), document-domain=(), encrypted-media=(), execution-while-not-rendered=(), execution-while-out-of-viewport=(), fullscreen=(), geolocation=(), gyroscope=(), magnetometer=(), microphone=(), midi=(), navigation-override=(), payment=(), picture-in-picture=(), publickey-credentials-get=(), screen-wake-lock=(), sync-xhr=(), usb=(), vr=(), wake-lock=(), xr-spatial-tracking=()",
        notes: "Permissions Policy allows a site to control which features and APIs can be used in the browser.",
      },
      {
        name: "FLoC (Federated Learning of Cohorts)",
        value: "floc",
        required: 2,
        suggestedValue: "interest-cohort=()",
        notes:
          "FLoC is a new API being developed by Google that is intended to replace third-party cookies and enable more personalized advertising experiences while protecting user privacy.",
      },
      {
        name: "X-Powered-By",
        value: "x-powered-by",
        required: 1,
        suggestedValue: "",
        notes:
          'X-Powered-By is a header that tells the browser what the content type of the returned content actually is. The only valid value for this header is "Content-Type: text/html; charset=utf-8".',
      },
      {
        name: "X-AspNet-Version",
        value: "x-aspnet-version",
        required: 0,
        suggestedValue: "",
        notes:
          'X-AspNet-Version is a header that tells the browser what the content type of the returned content actually is. The only valid value for this header is "Content-Type: text/html; charset=utf-8".',
      },
      {
        name: "X-AspNetMvc-Version",
        value: "x-aspnetmvc-version",
        required: 0,
        suggestedValue: "",
        notes:
          'X-AspNetMvc-Version is a header that tells the browser what the content type of the returned content actually is. The only valid value for this header is "Content-Type: text/html; charset=utf-8".',
      },
      {
        name: "X-DNS-Prefetch-Control",
        value: "x-dns-prefetch-control",
        required: 0,
        suggestedValue: "",
        notes:
          'X-DNS-Prefetch-Control is a header that tells the browser what the content type of the returned content actually is. The only valid value for this header is "Content-Type: text/html; charset=utf-8".',
      },
    ];
  },
  getMissingHeaders(headerArray) {
    let arr = [];
    let headers = this.securityHeaders();
    for (let index = 0; index < headers.length; index++) {
      const e = headers[index];
      if (e.required != 0) {
        let exists = headerArray[e.name] || headerArray[e.value];
        if (!exists) {
          arr.push(e);
        }
      }
    }
    return arr;
  },
  generatingMessage(completed) {
    switch (completed) {
      case 1:
        return "Sniffing...";
      case 2:
        return "Licking...";
      case 3:
        return "Tail wagging...";
      case 4:
        return "Tilting head...";
      case 5:
        return "Eye contact...";
      case 6:
        return "Jumping up...";
      case 7:
        return "Sighing...";
      case 8:
        return "Whimpering...";
      case 9:
        return "Nudging...";
      case 10:
        return "Following...";
      case 11:
        return "Sitting close...";
      case 12:
        return "Barking...";
      case 13:
        return "Growling...";
      case 14:
        return "Pawing...";
      case 15:
        return "Rolling over...";
      case 16:
        return "Bringing toys...";
      case 17:
        return "Finishing up...";
      default:
        return "[Calling Hounddog]";
    }
  },
  supportSubject() {
    return [
      {
        label: "Technical Issue",
      },
      {
        label: "Found a bug in the app",
      },
      {
        label: "Feature Suggestion",
      },
      {
        label: "Other",
      },
    ];
  },
  scanList() {
    return [
      {
        no: 1,
        selected: true,
        name: "Safety Score",
        key: "Safety",
        color: "yellow darken-2",
        progress: 0,
        order: 0,
      },
      {
        no: 2,
        selected: true,
        name: "Website Details",
        key: "Website",
        color: "green",
        progress: 0,
        order: 0,
      },
      {
        no: 3,
        selected: true,
        name: "SEO Technology",
        key: "SEOTech",
        color: "red",
        progress: 0,
        order: 0,
      },
      {
        no: 4,
        selected: true,
        name: "Google Search",
        key: "Google Search",
        color: "blue",
        progress: 0,
        order: 0,
      },
      {
        no: 5,
        selected: true,
        name: "HTTP Response Headers",
        key: "Headers",
        color: "red",
        progress: 0,
        order: 0,
      },
      {
        no: 6,
        selected: true,
        name: "SEO Related",
        key: "SEO",
        color: "blue",
        progress: 0,
        order: 0,
      },
      {
        no: 7,
        selected: true,
        name: "Server Details",
        key: "Server",
        color: "yellow darken-2",
        progress: 0,
        order: 0,
      },
      {
        no: 8,
        selected: true,
        name: "Domain Details",
        key: "Domain",
        color: "green",
        progress: 0,
        order: 0,
      },
      {
        no: 9,
        selected: true,
        name: "Redirect Chain",
        key: "Redirect Chain",
        color: "red",
        progress: 0,
        order: 0,
      },
      {
        no: 10,
        selected: true,
        name: "Reverse IP",
        key: "Reverse IP",
        color: "blue",
        progress: 0,
        order: 0,
      },
      {
        no: 11,
        selected: true,
        name: "VirusTotal",
        key: "virustotal",
        color: "red",
        progress: 0,
        order: 0,
      },
      {
        no: 12,
        selected: true,
        name: "Norton Safeweb",
        key: "norton",
        color: "blue",
        progress: 0,
        order: 0,
      },
      {
        no: 13,
        selected: true,
        name: "Google Transparency Report",
        key: "google",
        color: "yellow darken-2",
        progress: 0,
        order: 0,
      },
      {
        no: 14,
        selected: true,
        name: "MCafee Web Advisor",
        key: "mcafee",
        color: "green",
        progress: 0,
        order: 0,
      },
      {
        no: 15,
        selected: true,
        name: "Sucuri Scanner",
        key: "sucuri",
        color: "red",
        progress: 0,
        order: 0,
      },
      {
        no: 16,
        selected: true,
        name: "Page Speed",
        key: "Page Speed",
        color: "blue",
        progress: 0,
        order: 0,
      },
      {
        no: 17,
        selected: true,
        name: "GMetrix",
        key: "GMetrix",
        color: "red",
        progress: 0,
        order: 0,
      },
      {
        no: 18,
        selected: true,
        name: "Uptime Monitoring",
        key: "Uptime",
        color: "blue",
        progress: 0,
        order: 0,
      },
    ];
  },
};
